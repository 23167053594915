import React, { useEffect, useState } from "react";
import styles from "./homework.module.css";
import Button from "../../Components/./Button/button";
import axios from "../../axios/axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { ROLES, useUserContext } from "../../Components/UserContext/UserContext";
import moment from "moment";

function Homework() {
    const navigate = useNavigate();
    const { user } = useUserContext();
    const [homeworks, setHomeworks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(5);
    const [notHomework, setNotHomework] = useState(true);
    const [groups, setGroups] = useState([]);
    const [section, setSection] = useState('homeworks')

    useEffect(() => {
        axios
            .get(`/homework/${user.role === ROLES.TEACHER ? "getHomeworkTitlesForTeacher" : "titles"}`)
            .then(({ data }) => {
                setHomeworks(data);
                setIsLoading(false);
                setNotHomework(data.length === 0)
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axios.get('/group/findGroups')
            .then(({data}) => {
                setGroups(data?.group);
                setIsLoading(false)
            })
    }, []);

    const renderHomeworkRow = (homework) => (
        <tr
            key={homework.id}
            // onClick={() => navigate(`${homework.id}?students=none`)}
        >
            <td>{homework.title}</td>
            <td>{moment(homework.dueDate).format("DD.MM.YYYY")}</td>
            <td>{homework.maxPoints}</td>
        </tr>
    );

    return isLoading ? (
        <Loader />
    ) : (
        <div className={styles.container}>
            {user.role !== ROLES.STUDENT && (
                <div className={styles.tabsContainer}>
                    <div className={styles.tabs}>
                        <div className={`${styles.tab} ${section === 'homeworks' ? styles.selected : ''}`} onClick={() => setSection('homeworks')}>Homeworks</div>
                        <div className={`${styles.tab} ${section === 'groups' ? styles.selected : ''}`} onClick={() => setSection('groups')}>Groups</div>
                    </div>
                    {section === 'homeworks' &&
                        <Button
                            className={"bigPrimary"}
                            text={"+ Add homework"}
                            handleButtonClick={() => navigate('create')}
                            styles={{
                                boxShadow: "0 0 14px 0 #FFC03842",
                                padding: "7px 25px",
                            }}
                        />
                    }
                </div>
            )}
            {/*{user.role !== ROLES.STUDENT && open && (*/}
            {/*    <HomeworkModal setOpen={setOpen} setUpdate={setUpdate} update={update} />*/}
            {/*)}*/}
            {section === 'homeworks' ?
                <>
                    <table className={styles.table}>
                        <tbody>
                        {homeworks?.slice(0, count)?.map((homework) => renderHomeworkRow(homework))}
                        </tbody>
                    </table>
                    {!notHomework && homeworks?.length > 5 && (
                        <Button
                            className={"primary"}
                            text={count === homeworks?.length ? "See less" : "See more"}
                            handleButtonClick={() => {
                                setCount(count === 5 ? homeworks?.length : 5);
                            }}
                        />
                    )}
                    {notHomework && <p className={styles.notHomework}>You don't have any homework yet.</p>}
                </>
                :
                <>
                    {!groups?.length ? (
                        <p className={styles.notGroup}>There are no groups in progress currently.</p>
                    ) : (
                        <>
                            <div className={styles.courseGroups}>
                                {groups?.map((group) => (
                                    <div className={styles.course} key={group.id} onClick={() => navigate(`group/${group.assignCourseId}`)}>
                                        <p className={styles.groupName}>{group?.name}</p>
                                        <div className={styles.dataCont}>
                                            <div className={styles.cont}>
                                                <img src='/images/groupUser.png' alt='group' className={styles.image} />
                                                <p className={styles.text}>{group?.usersCount} students</p>
                                            </div>
                                            {/*<div className={styles.cont}>*/}
                                            {/*    <img src='/images/course.png' alt='course' className={styles.image} />*/}
                                            {/*    <p className={styles.text}>{group.lessonsCount} lessons</p>*/}
                                            {/*</div>*/}
                                            <div className={styles.cont}>
                                                <img src='/images/calendarGrey.png' alt='calendar' className={styles.image} />
                                                <p className={styles.text}>{moment(group.createdAt).format('DD.MM.YY')}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            }
        </div>
    );
}

export default Homework;

