import React from 'react';
import styles from './footerdesk.module.css'
import {Link} from "react-router-dom";
import List from './list'
import Logo from "./Logo";
import {useTranslation} from "react-i18next";

function FooterDesk() {

    const {t, i18n} = useTranslation()

    return (
            <>
                <Logo />
                <div className={styles.contcateg}>
                        <p className={styles.text}>{t('footer.categories')}</p>
                        <ul>
                            <li>
                                <Link className={styles.link} to={'/platform'}> {t('navigation.platform')} </Link>
                            </li>
                            <li>
                                <Link className={styles.link} to={'/courses'}> {t('footer.courses')} </Link>
                            </li>
                            <li>
                                <Link className={styles.link} to={'/company/about'}> {t('navigation.aboutUs')} </Link>
                            </li>
                            <li>
                                <Link className={styles.link} to={'/company/FAQ'}> {t('navigation.FAQ')} </Link>
                            </li>
                            <li>
                                <Link className={styles.link} to={'/company/contactUs'}> {t('navigation.contactUs')} </Link>
                            </li>
                            <li>
                                <a href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}company/careers`} target={'_blank'} className={styles.link}> {t('footer.careers')} </a>
                            </li>
                        </ul>
                </div>
                {window.innerWidth > 1150 ?
                    <div className={styles.cont}> <List /> </div> : <List />
                }
        </>
    )
}

export default FooterDesk;