import React, { useEffect, useState } from "react";
import styles from "./adminpayment.module.css";
import axios from "../../../axios/axios";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import Button from "../../../Components/Button/button";

const tHeads = ['Name Surname', 'Method', 'Amount', 'Status', 'Type', 'Billing Date', 'Invoice'];

function AdminPayment() {
    const [payment, setPayment] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { groupId } = useParams();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get('payment/getAllPayment', { params: { groupId, userName: searchTerm } })
                .then(({ data }) => setPayment(data));
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [groupId, searchTerm]);

    const renderPaymentDots = (orderStatus, type) => {
        const allSuccess = orderStatus.every(status => status === "Success");
        return Array.from({ length: payment?.paymentCount }).map((_, index) => {
            const status = orderStatus[index];
            if (status === "Success") {
                const isGreen = (allSuccess && orderStatus?.length === payment?.paymentCount) || type === "full";
                return (
                    <span
                        key={index}
                        className={`${styles.dot} ${isGreen ? styles.greenDot : styles.yellowDot}`}
                    />
                );
            } else if (status === "Failed") {
                return (
                    <span key={index} className={styles.redCross}>❌</span>
                );
            } else {
                return null;
            }
        });
    };

    const handleDownloadInvoice = payment => {
        console.log(payment?.paymentIds)
        axios.get(`/payment/downloadInvoice`, {
            params: { paymentIds: payment?.paymentIds },
            responseType: 'blob'
        }).then(({ data }) => {
            if (data.size === 0) {
                return;
            }
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            const filename = `Payment_Invoice_${payment?.user?.firstName}_${payment?.user?.lastName}.pdf`;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log("Error downloading invoice:", error);
        });
    };

    return (
        <>
            <div className={styles.titleContainer}>
                <p className={styles.title}>Payment</p>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder={"Search by username"}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchBar}
                    />
                    <CiSearch className={styles.searchIcon} />
                </div>
            </div>
            <table className={styles.table}>
                <thead>
                <tr>
                    {tHeads.map((name, i) => (
                        <th key={i}>
                            <span>{name}</span>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {payment?.payments?.map(payment => (
                    <tr key={payment?.id}>
                        <td>{payment?.user?.firstName} {payment?.user?.lastName}</td>
                        <td>{payment?.paymentWay}</td>
                        <td>{payment?.amount} AMD</td>
                        <td>
                            {/*<span*/}
                            {/*    className={`${styles.status} ${*/}
                            {/*        payment?.orderStatus[0] === "Success" ? styles.paid : styles.failed*/}
                            {/*    }`}*/}
                            {/*>*/}
                            {/*    {payment?.orderStatus[0]}*/}
                            {/*</span>*/}
                            <div className={styles.paymentProgress}>
                                {renderPaymentDots(payment?.orderStatus, payment?.type)}
                            </div>
                        </td>
                        <td style={{textTransform: "capitalize"}}>{payment?.type}</td>
                        <td>{moment(payment?.updatedAt).format("DD MMM, YYYY")}</td>
                        <td>
                            <Button
                                className={"primary"}
                                text={"Download"}
                                handleButtonClick={() => handleDownloadInvoice(payment)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default AdminPayment;
