import React from 'react'
import styles from "./list.module.css";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


function List() {

    const {t, i18n} = useTranslation()

    return(
        <>
            <div className={styles.smalldiv}>
                <p className={styles.text}>{t('footer.freeEducation')}</p>
                <ul className={styles.list}>
                    <li>
                        <a  target={'_blank'} href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}blog/page/1`} className={styles.link}> {t('footer.blog')} </a>
                    </li>
                    <li>
                        <a  target={'_blank'} href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}education`} className={styles.link}> {t('footer.education')} </a>
                    </li>
                </ul>
            </div>
    <div className={styles.smalldiv}>
        <p className={styles.text}>{t('footer.policies')}</p>
        <ul className={styles.list}>
            <li>
                <Link className={styles.link} to={'/termsOfUse'}> {t('footer.termsOfUse')} </Link>
            </li>
            <li>
                <Link className={styles.link} to={'/privacyPolicy'}> {t('footer.privacyPolicy')} </Link>
            </li>
            <li>
                <Link className={styles.link} to={'/cookies'}> {t('footer.cookiePolicy')} </Link>
            </li>
        </ul>
    </div>
    <div className={styles.smalldiv + ' ' + styles.contact}>
        <p className={styles.text}>{t('footer.contacts')}</p>
        <ul className={styles.list}>
            <li>
                <a href='tel:+37499790270' className={styles.link + ' ' + styles.tel}>
                    <img src='/images/armenianFlag.png' className={styles.image} alt={'flag'} />
                    &nbsp; +(374) 99 79 02 70
                </a>
            </li>
            <li>
                <a href='mailto:sayhello@tesvan.com' className={styles.link + ' ' + styles.firaSans}>
                    sayhello@tesvan.com
                </a>
            </li>
            <li>
                <p className={styles.link + ' ' + styles.notHover} > {t('footer.Armenia')} </p>
            </li>
        </ul>
    </div>
        </>
    )
}

export default List;