import React, { useState } from "react";
import styles from "./menu.module.css";
import { Link, useLocation } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import MenuDropdown from "../../../Components/MenuDropdown/MenuDropdown";
import Icons from "../../../Dashboard/DashLayout/Icons";
import LanguageSelect from "../../../Components/LanguageSelect/LanguageSelect";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Components/UserContext/UserContext";

function Menu({hasNewMessages, setHasNewMessages, newMessages}) {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const {user} = useUserContext()
    const {t, i18n} = useTranslation()
    const containsPath = ['about', 'FAQ', 'contactUs'].some((str) => location.pathname.includes(str));

    const handleHover = () => {
        setIsOpen(!isOpen);
    };

    const isLinkActive = (linkPath) => {
        return location.pathname.includes(linkPath);
    };

    return (
        <>
            <div className={styles.itemsdiv}>
                <Link to={"/"}>
                    <img className={styles.logo} src="/images/logo.png" alt="Logo" />
                </Link>
                <div className={styles.listdiv}>
                    <ul>
                        <li className={styles.li}>
                            <Link
                                className={
                                    isLinkActive("platform")
                                        ? `${styles.link} ${styles.active}`
                                        : styles.link
                                }
                                to={"/platform"}
                            >
                                {t("navigation.platform")}
                            </Link>
                        </li>
                        <li className={styles.li}>
                            <Link
                                className={
                                    isLinkActive("courses")
                                        ? `${styles.link} ${styles.active}`
                                        : styles.link
                                }
                                to={"/courses"}
                            >
                                {t("navigation.courses")}
                            </Link>
                        </li>
                        <li
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHover}
                            className={styles.li}
                        >
                            <a
                                className={
                                    isOpen
                                        ? `${styles.linkdrop} ${
                                            containsPath && isLinkActive('company')
                                                ? styles.active
                                                : ""
                                        }`
                                        : `${styles.link} ${
                                            containsPath && isLinkActive('company')
                                                ? styles.active
                                                : ""
                                        }`
                                }
                                style={{ cursor: "default" }}
                            >
                                {t("navigation.company")}{" "}
                                <RiArrowDownSLine
                                    className={isOpen ? styles.arrowhov : styles.arrow}
                                />
                            </a>
                            <div className={styles.cont} onMouseEnter={() => setIsOpen(true)}>
                                {isOpen && <MenuDropdown setOpened={setIsOpen} />}
                            </div>
                        </li>
                        <li className={styles.li}>
                            <a
                                href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}blog/page/1`}
                                target={"_blank"}
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                {t("navigation.blog")}
                            </a>
                        </li>
                        <li className={styles.li}>
                            <a
                                href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}company/careers`}
                                target={"_blank"}
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                {t("navigation.joinUs")}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={!user ? styles.logdiv : styles.logeddiv}>
                {!user ?
                    <>
                        <Link className={styles.link} to={"/login"}>
                            <span className={styles.login}>{t("navigation.login")}</span>
                            <span className={styles.icon}></span>
                        </Link>
                        <LanguageSelect />
                    </>
                    :
                    <Icons hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />
                }
            </div>
        </>
    );
}

export default Menu;
