import React, { useEffect, useState } from "react";
import styles from './teachersinglehomework.module.css';
import { Link, useParams, useSearchParams } from "react-router-dom";
import axios from "../../../axios/axios";
import moment from "moment";
import { RiArrowDownSLine } from "react-icons/ri";
import Button from "../../../Components/Button/button";

function TeacherSingleHomework() {
    const filterList = ["High to Low", "Low to High"];
    const { homeworkId, courseId } = useParams();
    const [homeworkData, setHomeworkData] = useState({});
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(10);
    const [selectedFilter, setSelectedFilter] = useState("DESC");
    const [searchParams] = useSearchParams();
    const areThereStudents = !searchParams.get('students');
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        axios.get(`homework/getHomeworkForTeacher/${homeworkId}`, {
            params: {
                filterType: selectedFilter,
                ...(courseId && { courseId }),
            },
        }).then(({ data }) => {
            setHomeworkData(data.homeWorkInfo);
            setUsers(data?.users);
        });
    }, [homeworkId, selectedFilter]);

    useEffect(() => {
        const lastUserId = sessionStorage.getItem('lastUserId');
        if (lastUserId && users.length > 0) {
            setCount(users?.length);
            setTimeout(() => {
                const userElement = document.querySelector(`[data-user-id="${lastUserId}"]`);
                if (userElement) {
                    userElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 0);
            sessionStorage.removeItem('lastUserId');
        }
    }, [users, count]);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter === filterList[0] ? "DESC" : "ASC");
        setShow(false);
    };

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={styles.container}>
            <div className={styles.homeworkTitle}>
                <p>{homeworkData.title}</p>
            </div>
            <div className={styles.descriptionCont}>
                <div className={styles.collapsibleDescription}>
                    <div
                        className={`${styles.description} ${isExpanded ? styles.expanded : styles.collapsed} ql-editor`}
                        dangerouslySetInnerHTML={{ __html: homeworkData?.description }}
                    />
                    <Button className={'primary'} handleButtonClick={toggleDescription} text={isExpanded ? 'Show Less' : 'Show More'} styles={{ marginTop: '10px' }} />
                </div>
                <div className={styles.dates}>
                    {homeworkData?.startDate && <span className={styles.date}>Start date: &nbsp;{moment(homeworkData.startDate).format("DD.MM.YYYY")}</span>}
                    <span className={styles.date}>Due date: &nbsp;{moment(homeworkData.dueDate).format("DD.MM.YYYY")}</span>
                </div>
            </div>
            {(users.length > 0 && areThereStudents) &&
                <div className={styles.tableContainer}>
                    <div className={styles.numberCont}>
                        <p className={styles.performances}>Student performances</p>
                        <p className={styles.number}>{users.length} students</p>
                    </div>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <td>Students</td>
                            <td
                                className={styles.pointsTh}
                                onMouseEnter={() => setShow(true)}
                                onMouseLeave={() => setShow(false)}
                            >
                                <div>
                                    Points
                                    <RiArrowDownSLine className={styles.arrow} />
                                </div>
                                {show &&
                                    <div className={styles.filterDrop}>
                                        {filterList.map((filter, index) => (
                                            <p
                                                key={index}
                                                className={styles.filter}
                                                onClick={() => handleFilterChange(filter)}
                                            >
                                                {filter}
                                            </p>
                                        ))}
                                    </div>
                                }
                            </td>
                            <td>Link</td>
                        </tr>
                        </thead>
                        <tbody>
                        {users.slice(0, count)?.map(student => (
                            <tr key={student?.userId} data-user-id={student?.userId}>
                                <td>
                                    <div className={styles.cell}>
                                        <div style={{ background: `url(${`https://platform.tesvan.com/server/${student?.image}`})` }} className={styles.student} />
                                        <p className={styles.name}>{`${student.firstName} ${student.lastName}`}{student?.status === 2 ? ' ✅' : ' ❌'}</p>
                                    </div>
                                </td>
                                <td className={styles.point}>{student.points} points</td>
                                <td>
                                    <Link
                                        to={`${student?.userId}`}
                                        onClick={() => sessionStorage.setItem('lastUserId', student?.userId)}
                                    >
                                            <span className={styles.link}>
                                                See results
                                            </span>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {users.length > 10 &&
                        <button
                            className={styles.button}
                            onClick={() => {
                                setCount(count === 10 ? users.length : 10);
                            }}
                        >
                            {count === users.length ? "See less" : "See more"}
                        </button>
                    }
                </div>
            }
        </div>
    );
}

export default TeacherSingleHomework;
