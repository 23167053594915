import React, {useState} from 'react'
import styles from './respMenu.module.css'
import {Link, useNavigate} from "react-router-dom";
import {RiArrowDownSLine} from "react-icons/ri";
import Button from "../../../Components/./Button/button";
import ResponsLangSelect from "../../../Components/ResponsLangSelect/ResponsLangSelect";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Components/UserContext/UserContext";

function ResponsiveMenu() {

    const [isOpen, setIsOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);
    const navigate = useNavigate();
    const { user } = useUserContext();
    const handleClick = () => {
        setIsOpen(!isOpen)
    }
    const {t, i18n} = useTranslation()

    return(
        <>
            <Link to={'/'}>
                <img className={styles.logo} src='/images/logo.png' alt={'logo'}/>
            </Link>
            <div>
                <img className={styles.menu} src='/images/menuIcon.png' onClick={handleClick} alt={'menuIcon'}/>
            </div>
            {isOpen &&
                <div className={styles.bigcont}>
                    <div className={styles.container}>
                        <div className={styles.cont}>
                            <div className={styles.contul}>
                               <div className={styles.itemarrow}>
                                   <Link to={"/platform"} className={styles.link}  onClick={handleClick}> {t("navigation.platform")} </Link>
                               </div>
                                <div className={styles.item}>
                                    <Link className={styles.link} to={'/courses'} onClick={handleClick}> {t("navigation.courses")} </Link>
                                </div>
                                <div className={!companyOpen ? styles.itemarrow : styles.itemopen} onClick={() => setCompanyOpen(!companyOpen)}>
                                    <p className={styles.link}> {t("navigation.company")} </p>
                                    <RiArrowDownSLine
                                        className={!companyOpen ? styles.icon : styles.iconopen}
                                        onClick={() => setCompanyOpen(!companyOpen)}
                                    />
                                </div>
                                {companyOpen &&
                                    <div className={styles.divlist}>
                                        <ul>
                                            <li className={styles.li}>
                                                <Link onClick={handleClick} className={styles.link}
                                                      to={'/company/about'}> {t('navigation.aboutUs')} </Link>
                                            </li>
                                            <li onClick={handleClick} className={styles.li}>
                                                <Link className={styles.link}
                                                      to={'/company/FAQ'}> {t('navigation.FAQ')} </Link>
                                            </li>
                                            <li onClick={handleClick} className={styles.li}>
                                                <Link className={styles.link}
                                                      to={'/company/contactUs'}> {t('navigation.contactUs')} </Link>
                                            </li>
                                        </ul>
                                    </div>
                                }
                                <div className={styles.item}>
                                    <a
                                        href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}blog/page/1`}                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        className={styles.link}
                                    >
                                        {t("navigation.blog")}
                                    </a></div>
                                <div className={styles.item}>
                                    <a
                                        href={`https://tesvan.com/${i18n.language !== 'en' ? `${i18n.language}/` : ''}company/careers`}
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        className={styles.link}
                                    >
                                        {t("navigation.joinUs")}
                                    </a></div>
                                <ResponsLangSelect/>
                            </div>
                            <div className={styles.contbtn}>
                                {/*<Button className={'bigPrimary'} text={'Interview request'}/>*/}
                                {!(localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) ?
                                    <Link onClick={handleClick} className={styles.linkbutton} to={"/login"}>
                                        <span className={styles.login}>{t("navigation.login")}</span>
                                        <span className={styles.logicon}></span>
                                    </Link>
                                    :
                                    <Button
                                        className={"bigPrimary"}
                                        handleButtonClick={() => navigate(`/${user?.role.toLowerCase()}`)}
                                        text={t('dashboardLayout.goToDashboard')}
                                        styles={{ alignSelf: "center" }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ResponsiveMenu;