import React, { useEffect, useState } from 'react';
import styles from "./AdminCreateNewQuiz.module.css"
import { Link } from "react-router-dom";
import EditDelete from "../AdminUsers/EditDelete/EditDelete";
import axios from "../../../axios/axios";
import Modal from "../../../Components/Modal/Modal";
import Loader from "../../../Components/Loader/Loader";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";

function AdminCreateNewQuiz(props) {
    const [deleteLessonOpen, setDeleteLessonOpen] = useState(false);
    const [selectedQuizz, setSelectedQuizz] = useState(null);
    const [update, setUpdate] = useState(null);
    const {user} = useUserContext();

    const [count, setCount] = useState(10)
    const handleDelete = quizzId => {
        axios.delete(`/quizz/delete/${quizzId}`).then(() => {
            setDeleteLessonOpen(false)
            setUpdate(!update)
            setQuizz(prevQuizz => prevQuizz.filter(quizz => quizz.id !== quizzId));
        }).catch(error => {
            console.error('Error deleting quiz: ', error);
        });
    };

    const [quizz, setQuizz] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios.get(`quizz/${user.role === ROLES.TEACHER ? "getAllTitleForTeacher" : "getAll"}`)
            .then(response => {
                setQuizz(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching quizzes: ', error);
                setLoading(false);
            });
    }, []);
    return (
        <>
            {
                loading ? <Loader/> : (
                    <div className={styles.contai}>
                        <div className={styles.cont}>
                            <p className={styles.title}>Quizzes</p>
                            <Link to={'addquiz'} className={styles.link}>+ Create new quiz</Link>
                        </div>
                        <div>
                            <div className={styles.container}>
                                <table className={styles.table}>
                                    <thead>
                                    <tr>
                                        <td>Quiz name</td>
                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {quizz?.slice(0, count).map((student, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Link to={`editquizz/${student.id}`}>

                                                    <p className={styles.name}>{student.title}</p>
                                                    </Link>
                                                </td>

                                                <td>
                                                    <div className={styles.divon}>
                                                        <img className={styles.img} src={'/images/copyiconuser.png'}
                                                             alt={'copy'}/>
                                                        <Link to={`editquizz/${student.id}`}>
                                                            <img className={styles.img} src={'/images/paniconuser.png'}
                                                                 alt={'pan'}/>
                                                        </Link>
                                                        <img
                                                            onClick={() => {
                                                                setSelectedQuizz(student);
                                                                setDeleteLessonOpen(true);
                                                            }}
                                                            className={styles.img}
                                                            src={'/images/basketiconuser.png'}
                                                            alt={'basket'}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                {quizz?.length > 10 &&
                                    <button
                                        className={styles.button}
                                        onClick={() => {
                                            setCount(count === 10 ? quizz?.length : 10);
                                        }}
                                    >
                                        {count === quizz?.length ? "See less" : "See more"}
                                    </button>
                                }
                            </div>
                        </div>
                        {deleteLessonOpen && selectedQuizz &&
                            <Modal
                                setOpen={setDeleteLessonOpen}
                                question={`Are you sure to delete the ${selectedQuizz?.title} quizz ?`}
                                onConfirm={() => handleDelete(selectedQuizz.id)}
                                confirmText={'Delete'}
                                onClose={() => setDeleteLessonOpen(false)}
                                closeText={'Close'}
                            />
                        }
                    </div>
                )
            }
        </>

    );
}

export default AdminCreateNewQuiz;