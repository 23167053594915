import React, {useEffect, useState} from "react";
import styles from "./CourseID.module.css"
import {Link, NavLink, Outlet, useNavigate, useParams} from "react-router-dom";
import axios from "../../../../axios/axios";
import {ROLES, useUserContext} from "../../../../Components/UserContext/UserContext";

function Settings() {

    const { courseIdEdit } = useParams();
    const [course,setCourse] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await axios.get(`/courses/getCourseForAdmin/${courseIdEdit}`);
                setCourse(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            }
        };

        fetchData();

    }, []);


    const navLinks = [
        { to: 'lessons', text: 'Lessons' },
        { to: 'settings', text: 'Settings' }
    ].filter(link => link !== null);

    return(
        <>
            <div className={styles.co}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{cursor: 'pointer'}} onClick={() => navigate(-1)} src={'/images/backIcon.png'}/>
                    <p className={styles.ti}>Courses</p>
                </div>
                <Link to={'/admin/courses/newcourse'} className={styles.links}>+ Create new course</Link>
            </div>
            <div className={styles.cont}>
                <div className={styles.left}>
                    <p className={styles.title}>{course?.title_en}</p>
                    <p className={styles.text}>{course?.description_en}</p>
                </div>
                <div className={styles.right}>
                    <img src='/images/LessonsInProgress.png' className={styles.imgteacher}/>
                </div>
            </div>
            <div className={styles.sections}>
                {navLinks.map((link) => (
                    <NavLink
                        key={link.to}
                        to={link.to}
                        className={({isActive}) =>
                            isActive ? [styles.active, styles.link].join(' ') : styles.link
                        }
                    >
                        {link.text}
                    </NavLink>
                ))}
            </div>
            <div>
                <Outlet/>
            </div>
        </>
    )
}

export default Settings;