import React, {useState} from "react";
import styles from './languageselect.module.css'
import { RiArrowDownSLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function LanguageSelect() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || "am");
    const { i18n } = useTranslation();
    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(() => {
            setSelectedLanguage(lng);
            setIsModalOpen(false);
            localStorage.setItem('language', lng)
            window.location.reload()
        });
    };

    const handleModalHover = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <div
                onMouseEnter={handleModalHover}
                onMouseLeave={handleModalHover}
            >
                <span
                    className={(isModalOpen ? styles.linkDropdown : styles.link) + " " + styles.line}
                >
                    <p className={styles.selected + ' ' + styles.pointer}>
                        {selectedLanguage === "en" ? "Eng" : selectedLanguage === "ru" ? "Rus" : "Arm" }
                        <RiArrowDownSLine
                            className={isModalOpen ? styles.arrowHover : styles.arrow}
                        />
                    </p>
                    <div className={styles.cont} onMouseEnter={() => setIsModalOpen(true)}>
                        {isModalOpen && (
                            <div className={styles.dropdown}>
                                <img src={"/images/triangle.png"} className={styles.triangle} alt={'triangle'} />
                                <ul>
                                    {selectedLanguage !== "en" && (
                                        <li>
                                            <p
                                                className={styles.link}
                                                onClick={() => handleChangeLanguage("en")}
                                            >
                                                Eng
                                            </p>
                                        </li>
                                    )}
                                    {selectedLanguage !== "am" && (
                                        <li>
                                            <p
                                                className={styles.link}
                                                onClick={() => handleChangeLanguage("am")}
                                            >
                                                Arm
                                            </p>
                                        </li>
                                    )}
                                    {selectedLanguage !== "ru" && (
                                        <li>
                                            <p
                                                className={styles.link}
                                                onClick={() => handleChangeLanguage("ru")}
                                            >
                                                Rus
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </span>
            </div>
        </>
    )
}

export default LanguageSelect;
